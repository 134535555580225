.wrapper {
  display: flex;
  flex-direction: column;
  gap: 42px;
  align-items: flex-start;
  width: 100%;
}

.wrapperForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.wrapperTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.wrapperProfileImage {
  display: flex;
  justify-content: center;
  width: 100%;
}
