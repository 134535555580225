.warapper {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.wrapperHeader {
  padding: 12px 24px;
  border-bottom: 1px solid #c1c1c1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  border-radius: 8px 8px 0 0;
}

.wrapperHeader p {
  font-size: 12px;
  margin: 0;
  font-weight: 500;
}

.wrapperBody {
  padding: 24px;
}
