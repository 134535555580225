.wrapperItem {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.textService {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.actionService {
  border-radius: 8px !important;
  min-width: 80px !important;
  width: 80px;
}

.wrapperItemList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #dbdbdb;
  padding: 12px 0;
  gap: 24px;
}

.wrapperContent {
  display: flex;
  align-items: center;
  gap: 14px;
  flex: 1 0 0;
}

.wrapperContent img {
  width: 62px;
  height: 62px;
  object-fit: contain;
}

.contentInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.contentTitleInfo {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.contentDescInfo {
  color: #000;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}
