.wrapperFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 12px;
  margin-bottom: 12px;
}

.wrapperInput {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
  justify-content: center;
}

.inputStyle {
  width: 100% !important;
  min-width: 230px;
}

@media only screen and (min-width: 768px) {
  .wrapperInput {
    display: flex;
    gap: 12px;
    flex: 1;
  }
  .wrapperFilter {
    flex-wrap: unset;
  }
}
