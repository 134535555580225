.wrapperColumnHeader {
  display: flex;
  padding: 8px;
  gap: 8px;
  max-width: 221px;
  overflow: scroll;
}

.column-header {
  display: flex;
  padding: 4px 24px;
  position: relative;
  align-items: center;
  flex-direction: column;
  color: #282828;
  border-radius: 6px;
}

.hover-column-header:hover {
  color: var(--primary) !important;
}

.wrapperColumnDate {
  display: flex;
  padding: 0 16px;
  align-items: center;
  border-left: 1px solid #e5e5e5;
}

.textNeutral10 {
  color: #ffffff;
}

.textPrimaryHover:hover {
  color: var(--primary);
}

.text-neutral-60 {
  color: #adadad;
}

.bg-neutral-10 {
  background-color: #ffffff;
}

.bg-neutral-30 {
  background-color: #f0f0f0;
}

.borderPrimary {
  border: 1px solid var(--primary);
  color: var(--primary) !important;
}

.wrapperSelectDate {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
}

.wrapperTimeSlot {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  width: 100%;
  height: 100%;
  max-height: 400px;
  overflow-y: auto;
  padding: 12px;
  gap: 12px;
}

.pillSelected {
  background: var(--primary) !important;
  border: 1px solid var(--primary-light) !important;
  color: #ffffff !important;
}

.pillDisable {
  border: 1px solid #cbcbcb !important;
  background: #f0f0f0 !important;
  color: #000000 !important;
  cursor: not-allowed;
}

.wrapperPillField {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(16, 24, 40, 0.1);
  background: #ffffff;
  color: #000000;
}

.bgLightPrimary {
  background: var(--primary-light);
}

.minHeight120 {
  min-height: 120px;
}

@media only screen and (min-width: 768px) {
  .wrapperTimeSlot {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .wrapperColumnHeader {
    max-width: 700px;
  }
  .column-header {
    padding: 8px 24px;
  }
}
