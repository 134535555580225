.wrapper {
  display: flex;
  flex-direction: column;
  gap: 42px;
  align-items: flex-start;
  width: 100%;
}

.wrapperForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
