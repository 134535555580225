.tableContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-end;
  align-items: flex-end;
}

.paymentTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.paymentTable th,
.paymentTable td {
  border: 1px solid #ddd;
  padding: 6px;
  text-align: left;
}

.paymentTable th {
  background-color: #f2f2f2;
  font-weight: bold;
  font-size: 12px;
}

.paymentTable td {
  background-color: #fff;
  font-size: 12px;
}

.paymentTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.wrapperTotalpayment {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.wrapperTotalpayment p {
  margin: 0;
}

.wrapperTotalpayment p:nth-child(1) {
  font-size: 12px;
  color: #7a7a7a;
}

.wrapperTotalpayment p:nth-child(2) {
  font-size: 18px;
}

@media print {
  .table-container {
    page-break-inside: avoid;
  }

  .header,
  .footer {
    display: none;
  }

  body {
    margin: 0;
    padding: 0;
  }

  @page {
    margin: 10mm;
    size: A4;
  }
}
