.circle {
  width: 16px;
  height: 16px;
  border-radius: 100px;
}

.columnHead {
  border: 1px solid #e5e5e5;
  padding: 10px 12px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  min-width: 130px;
}

.columnHeadFirst {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #ffffff !important;
  padding: 0px;
}

.contentColumnHead {
  box-shadow: 10px 0 8px -10px rgba(16, 24, 40, 0.12);
  width: 100%;
  height: 100%;
  padding: 10px 12px;
}

.contentColumnBodyFirst {
  padding: 4px 8px;
  width: 100%;
  height: 100%;
}

.columnBody {
  border: 1px solid #e5e5e5;
  padding: 4px;
  width: 130px;
  vertical-align: top;
}

.columnVerticalCenter {
  vertical-align: middle !important;
}

.columnBodyDisable {
  background: var(
    --disable,
    linear-gradient(
      0deg,
      rgba(199, 199, 199, 0.15) 0%,
      rgba(199, 199, 199, 0.15) 100%
    ),
    url("../../../images/stripes.png") white 90% / cover no-repeat
  );
}

.wrapperColumnBody {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: column;
}

.columnBodyFirst {
  position: sticky;
  left: -1px;
  z-index: 2;
  background: #ffffff;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  padding: 0px;
}

.columnBodyFirstContent {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  background: #f6f6f6;
  font-weight: 500;
  padding: 10px 12px;
  min-height: 65px;
}

.highlightTime {
  background-color: var(--primary-light) !important;
}

.cardReservation {
  cursor: pointer;
  padding: 10px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid var(--neutral-20, #f6f6f6);
  background: var(--neutral-10, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.12);
  width: 100%;
  border-style: inset;
  border-top: 0;
  position: relative;
}

.cardReservationBooking {
  border-left: 6px solid var(--blue-light);
}

.cardReservationActive {
  border-left: 6px solid #09bd3c;
}

.cardReservationDone {
  border-left: 6px solid #ed8030;
}

.cardReservationCancel {
  border-left: 6px solid #fc2e53;
}

.cardReservationWaitingPayment {
  border-left: 6px solid #d653c1;
}

.cardReservation p {
  width: 130px;
  font-size: 12px;
}

.circleInfo {
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background: #e5e5e5;
  position: absolute;
  top: 4px;
  right: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.circleInfo i {
  font-size: 10px;
}

.buttonAddReservation {
  border: 1px dashed var(--primary);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
  font-size: 24px;
  padding: 12px 32px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}

.cellContent {
  position: relative;
}

.columnBody:hover .buttonAddReservation {
  opacity: 1;
}

.positionDatePicker {
  position: absolute !important;
  top: -20px;
  visibility: hidden;
}
