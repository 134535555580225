/* SECTION NAVBAR */
.navbarPosition {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.navbarReservationPosition {
  display: flex;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  align-items: center;
  justify-content: center;
  background: #ffffff;
}

.navbarWrapper,
.navbarReservationWrapper {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  background: #ffffff;
  padding: 12px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: sticky;
}

.navbarReservationWrapper {
  width: 620px;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;
}

.navbarWrapperLogo {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: space-between;
}

.navbarLogo {
  width: 150px;
}

.navbarLink {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.navbarLink a {
  margin: 0;
  color: #737b8b;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
}

.navbarLink a:hover {
  color: var(--primary);
}

.navbarAction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.navbarMobileWrapper {
  height: 100%;
  transition: all 1s ease;
  width: -webkit-fill-available;
  z-index: 3;
  transform: translate(-105%);
  background: white;
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 24px;
}

.showNavbarMobile {
  min-height: 100vh;
  transform: translate(0);
}

.navbarMobileAction {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.navbarMobileButton {
  font-weight: 500 !important;
  height: 35px;
  font-size: 12px !important;
}

.navbarCall {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.imageClinic {
  width: 140px;
  max-height: 60px;
  object-fit: contain;
  object-position: left;
}

.textCallUs {
  color: #27ae60;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  text-decoration-line: underline;
  margin: 0;
}

.textNeedHelp {
  color: #737b8b;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}
/* SECTION NAVBAR */

@media only screen and (min-width: 768px) {
  .navbarWrapper {
    padding: 10px 160px;
  }

  .navbarReservationWrapper {
    padding: 10px 0px;
  }

  .navbarLogo {
    width: 200px;
  }

  .navbarLink {
    flex-direction: row;
    gap: 48px;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .navbarLink a {
    font-size: 14px;
  }
}
