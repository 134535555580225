.wrapper {
  padding: 28px;
  display: flex;
  flex-direction: column;
  gap: 63px;
}

.titleStep {
  font-weight: 600;
  font-size: 60px;
}

.descStep {
  font-size: 22px;
  margin-bottom: 0;
}

@media screen and (min-width: 800px) {
  .wrapper {
    padding: 50px 120px;
  }
}
