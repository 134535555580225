.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.actionWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
}

.notesConfirm {
  margin: 0;
  font-size: 14px;
}
