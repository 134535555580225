/* SECTION FOOTER */
.footerWrapper,
.footerReservation {
  background: var(--primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  height: 400px;
  position: relative;
  padding: 40px 24px 0;
  z-index: 0;
}

.footerWrapperNoJoin {
  height: 300px;
  padding: 0 24px;
}

.footerReservation {
  height: 64px;
  padding: 0;
}

.footerIconWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
}

.footerIconWrapper img {
  width: 24px;
}

.footerLinkWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  row-gap: 16px;
  flex-wrap: wrap;
  width: 100%;
}

.footerLinkWrapper a {
  margin: 0;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
}

.footerLinkWrapper a:hover {
  color: #ffffff;
}

.footerCopyRight {
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  margin: 0;
}
/* SECTION FOOTER */

/* SECTION BOTTOM */
.wrapperBottom {
  margin-top: 132px;
}
/* SECTION BOTTOM */

/* SECTION JOIN */
.joinWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  background: var(--primary-light);
  width: 90%;
  border-radius: 30px;
  position: absolute;
  top: -120px;
  gap: 24px;
}

.joinWrapper p {
  color: var(--primary);
  margin: 0;
  font-weight: 700;
  font-size: 24px;
}

.joinButton {
  width: 150px;
  font-weight: 600 !important;
}
/* SECTION JOIN */

@media only screen and (min-width: 768px) {
  /* SECTION FOOTER */
  .footerWrapper {
    padding: 100px 0px 0px;
  }
  .footerWrapperNoJoin {
    padding: 0px;
  }
  .footerLinkWrapper {
    gap: 69px;
    justify-content: none;
    width: auto;
  }
  .footerCopyRight {
    font-size: 14px;
  }
  .wrapperBottom {
    margin-top: 150px;
  }
  /* SECTION FOOTER */

  /* SECTION JOIN */
  .joinWrapper {
    flex-direction: row;
    padding: 50px 60px;
    border-radius: 50px;
    width: 70%;
  }
  .joinWrapper p {
    font-size: 38px;
    line-height: 55px;
    max-width: 500px;
  }
  /* SECTION JOIN */
}
