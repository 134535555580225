.wrapperInput {
  position: relative;
  display: flex;
  align-items: center;
}

.iconEye {
  position: absolute;
  font-size: 18px;
  top: 16px;
  right: 14px;
  cursor: pointer;
}
