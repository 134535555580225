.wrapperProfile {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
}

.imageProfile {
  width: 200px;
  height: 200px;
  object-fit: contain;
  cursor: pointer;
}

.iconUploadProfile {
  position: absolute;
  right: -16px;
  top: -14px;
  font-size: 18px;
  cursor: pointer;
  color: var(--primary);
}
