.warapperPayment {
  border-radius: 8px;
  border: 1px solid var(--primary);
  background: var(--primary-light);
  padding: 24px;
}

.warpperPaymentNoBg {
  background: none !important;
}

.labelForm {
  font-weight: 700;
  margin-bottom: 6px;
}

.wrapperTotalAmountSuccess {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.wrapperTotalAmountSuccess p {
  margin: 0;
}

.wrapperTotalAmountSuccess p:nth-child(1) {
  font-size: 14px;
  font-weight: 500;
}

.wrapperTotalAmountSuccess p:nth-child(2) {
  font-size: 20px;
  font-weight: 600;
  color: var(--primary);
}

.wrapperDiscountItem p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.wrapperDiscountItem p:nth-child(2) {
  color: #ed8030;
}
