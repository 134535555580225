.positionSpinner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(243, 240, 249, 0.3);
  top: 0;
}
