.modalWrapper {
  position: relative;
}

.modalTitleWrapper {
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 12px;
}

.closeIcon {
  position: absolute;
  right: 18px;
  top: 18px;
  font-size: 18px;
  color: red;
  cursor: pointer;
}

.actionWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: flex-end;
}
