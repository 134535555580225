.wrapperTitleInfo {
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
}

@media only screen and (min-width: 768px) {
  .wrapperTitleInfo {
    justify-content: center;
  }
}
