.wrapperItem {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.textService {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  text-transform: lowercase;
}

.textService:first-letter {
  text-transform: capitalize;
}

.actionService {
  border-radius: 8px !important;
  min-width: 80px !important;
  width: 80px;
}

.wrapperItemList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #dbdbdb;
  padding: 12px 0;
}
