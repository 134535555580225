.wrapperUpload {
  border: 1px solid #f0f0f0;
  border-radius: 15px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
}

.sizeIcon {
  font-size: 24px;
  color: #adadad;
}

.labelUpload {
  margin-bottom: 0;
  font-size: 12px;
  cursor: pointer;
}

.labelFileName {
  margin-bottom: 0;
  font-size: 12px;
  cursor: pointer;
  color: #3498db;
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.labelFileName:hover {
  text-decoration: underline;
}

.wrapperEmptyImage {
  color: #cbcbcb;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.wrapperEmptyImage p {
  margin: 0;
}

.imageEmpty {
  font-size: 96px;
}

.wrapperAction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: fit-content;
}

.wrapperAction p {
  color: #282828;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.actionRounded {
  border-radius: 100px;
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.wrapperItemFile {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
}

.cardFileView {
  width: 80px !important;
  height: 80px !important;
}

.cardFileItem {
  display: flex;
  width: 130px;
  height: 130px;
  padding: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid #e5e5e5;
  position: relative;
  gap: 12px;
}

.cardFileItem img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.buttonDeleteFile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background: #fc2e53;
  border-radius: 100px;
  position: absolute;
  right: 0;
  top: 0;
  color: white;
  right: -9px;
  top: -6px;
  font-size: 12px;
  cursor: pointer;
}
