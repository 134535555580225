.wrapperTitle {
  display: flex;
  align-items: center;
  gap: 16px;
}

.textTitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}

.iconBack {
  font-size: 20px;
  cursor: pointer;
  color: #000000;
}
