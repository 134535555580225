/* SECTION HERO */
.heroWrapper {
  background: linear-gradient(
    270deg,
    rgba(151, 71, 255, 0.22) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  padding: 80px 24px 0px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  flex-wrap: wrap;
}

.wrapperDescHero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 18px;
  max-width: 546px;
}

.titleHero {
  color: var(--primary);
  font-family: Poppins;
  font-size: 28px;
  font-weight: 700;
  margin: 0;
}

.descHero {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.buttonHero {
  border-radius: 8px !important;
  height: 35px;
  width: 154px;
  font-weight: 500 !important;
}
/* SECTION HERO */

.titleSection {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

/* SECTION KEUNGGULAN */
.keunggulanWrapper {
  display: flex;
  padding: 48px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  position: relative;
  flex-wrap: wrap;
}

.keunggulanWrapper::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 100px;
  right: 100px;
  border-bottom: 1px dashed #737b8b;
}

.keunggulanItemWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex-wrap: wrap;
  gap: 24px;
}

.keunggulanItem {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.keunggulanItemText {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  width: 267px;
}

.iconItemWrapper {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #f2f2f2;
  color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
}
/* SECTION KEUNGGULAN */

/* SECTION SERVICE */
.serviceWrapper {
  display: flex;
  padding: 28px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
}

.serviceItemWrapper {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

.serviceItem {
  display: flex;
  padding: 24px;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  border-radius: 8px;
  background: var(--primary);
}

.serviceWrapperDesc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.serviceItemTitle {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  text-transform: lowercase;
}

.serviceItemTitle:first-letter {
  text-transform: capitalize;
}

.serviceItemDesc {
  font-size: 12px;
  color: #fff;
  margin: 0;
}
/* SECTION SERVICE */

/* SECTION CLINIC */
.clinicWrapper {
  display: flex;
  padding: 48px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  background: #fafafa;
}

.clinicWrapperList {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  justify-contet: space-between;
  flex-wrap: wrap;
}

.clinicItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;

  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
}

.clinicItem img {
  width: 100%;
  height: 150px;
  border-radius: 8px 8px 0px 0px;
}

.clinicItemInfo {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.clinicInfoTitle {
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.infoAddress {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.infoAddress i {
  color: #999999;
  font-size: 16px;
}

.infoAddress p {
  margin: 0;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.clinicGoAll {
  color: var(--primary);
  font-size: 12px;
  font-weight: 700;
  text-decoration-line: underline;
  margin: 0;
  cursor: pointer;
}

/* SECTION CLINIC */

@media only screen and (min-width: 768px) {
  /* SECTION HERO */
  .heroWrapper {
    padding: 60px 100px 0px 100px;
    height: 592px;
  }
  .titleHero {
    font-size: 36px;
  }
  .descHero {
    font-size: 16px;
  }
  .buttonHero {
    height: 44px;
    width: 194px;
    font-weight: 600 !important;
  }
  /* SECTION HERO */

  /* SECTION KEUNGGULAN */
  .keunggulanWrapper {
    padding: 50px 100px;
  }

  .keunggulanItemWrapper {
    justify-content: space-between;
  }
  /* SECTION KEUNGGULAN */

  /* SECTION SERVICE */
  .serviceWrapper {
    padding: 50px 100px;
  }

  .serviceItemWrapper {
    flex-wrap: unset;
  }
  /* SECTION SERVICE */

  /* SECTION CLINIC */
  .clinicWrapper {
    padding: 50px 100px;
  }

  /* SECTION CLINIC */
}
