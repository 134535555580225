.wrapperTitleSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
}

.titleSection {
  color: #737b8b;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  text-transform: uppercase;
}

.titleSectionDesc {
  font-weight: 600;
  font-size: 18px;
  margin: 0;
}

/* SECTION HERO */
.heroWrapper {
  background: linear-gradient(
    180deg,
    rgba(151, 71, 255, 0.22) 0%,
    rgba(217, 217, 217, 0) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 100px 0px 50px;
  flex-wrap: wrap;
}

.heroContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  gap: 28px;
}

.heroInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
}

.heroTitle {
  font-weight: 600;
  font-size: 28px;
  color: #000000;
  margin: 0;
}

.heroDesc {
  font-weight: 400;
  font-size: 12px;
  margin: 0;
}

.heroRegister {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.registerFeature {
  color: var(--primary);
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
}

.heroRegisterButton {
  width: 120px;
  font-weight: 500;
}
/* SECTION HERO */

/* SECTION COLLABORATE */
.collaborateWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px;
  gap: 24px;
}

.collaborateTitle {
  font-weight: 600;
  font-size: 16px;
  margin: 0;
}

.collaborateLogo {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
}

.collaborateLogo img {
  width: 100px;
}
/* SECTION COLLABORATE */

/* SECTION FIRST ABOUT */
.whyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 42px 24px;
  gap: 32px;
}

.whyContentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 24px;
}

.whyContentItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.whyTitleContent {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

.whyIconContent {
  width: 40px;
}

.whyOptionWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.whyOptionItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.whyOptionItem i {
  font-size: 16px;
  color: var(--primary);
}

.whyOptionItem p {
  font-weight: 500;
  font-size: 12px;
  margin: 0;
}

/* SECTION FIRST ABOUT */

/* SECTION SERVICE */
.serviceWrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding: 42px 24px;
  gap: 42px;
}

.serviceWrapper img {
  width: 400px;
}

.serviceContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}

.serviceItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.serviceOption {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

.serviceOption i {
  color: #999999;
}

.serviceOption p {
  font-weight: 500;
  font-size: 12px;
  margin: 0;
}
/* SECTION SERVICE */

.wrapperIntegration {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.labelIntegration {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (min-width: 768px) {
  .wrapperTitleSection {
    gap: 8px;
  }
  .titleSection {
    font-size: 16px;
  }
  .titleSectionDesc {
    font-size: 24px;
  }

  /* SECTION HERO */
  .heroWrapper {
    padding: 150px 0px 50px;
  }
  .heroContent {
    padding: 0px 160px;
    flex-direction: row;
    gap: 150px;
  }
  .heroInfo {
    gap: 24px;
  }
  .heroTitle {
    font-size: 43px;
    line-height: 70px;
  }
  .heroDesc {
    font-size: 14px;
  }
  .heroRegisterButton {
    width: 200px;
  }
  .registerFeature {
    font-size: 16px;
  }
  /* SECTION HERO */
  /* SECTION COLLABORATE */
  .collaborateWrapper {
    padding: 70px 160px;
    gap: 32px;
  }
  .collaborateTitle {
    font-size: 18px;
  }
  .collaborateLogo {
    justify-content: space-between;
  }
  .collaborateLogo img {
    width: 150px;
  }
  .whyWrapper {
    padding: 70px 160px;
  }
  /* SECTION COLLABORATE */
  /* SECTION FIRST ABOUT */
  .whyWrapper {
    gap: 50px;
  }
  .whyIconContent {
    width: 50px;
  }
  .whyTitleContent {
    font-size: 18px;
  }
  .whyOptionItem p {
    font-size: 14px;
  }
  .whyOptionItem i {
    font-size: 18px;
  }
  /* SECTION FIRST ABOUT */
  /* SECTION SERVICE */
  .serviceWrapper {
    padding: 70px 160px;
    gap: 100px;
    flex-direction: row;
  }
  .serviceContentWrapper {
    gap: 48px;
  }
  .serviceOption p {
    font-size: 14px;
  }
  /* SECTION SERVICE */
}
