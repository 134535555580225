.wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}
.wrapperItem {
  background: #f6f6f6;
  border-radius: 8px;
  padding: 12px 24px;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.value {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
