.wrapper {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
}

.title {
  font-size: 35px;
  margin-bottom: 0;
}

.caption {
  font-size: 12px;
  text-align: center;
}

.imageLogo {
  width: 200px;
}

@media screen and (min-width: 800px) {
  .title {
    font-size: 60px;
  }
  .caption {
    font-size: 22px;
  }
  .imageLogo {
    width: auto;
  }
}
