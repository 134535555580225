.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.wrapperCamera {
  position: relative;
  width: 100%;
  height: 500px;
  margin-top: 12px;
}

.wrapperCamera img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.wrapperAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.webcam {
  width: 100%;
  height: 100%;
  position: absolute; /* Ensure it covers the parent */
  top: 0;
  left: 0;
  object-fit: cover; /* Ensure the video covers the container */
}

.buttonTake {
  border-radius: 100px;
  background: #fc2e53;
  width: 60px;
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.buttonTakeSmall {
  border: 1px solid #fff;
  border-radius: 100px;
  background: #fc2e53;
  width: 45px;
  height: 45px;
}

.buttonHelp {
  display: flex;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #282828;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
}

.visibilityHidden {
  visibility: hidden;
}
