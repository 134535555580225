.wrapperItemDay {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border: 1px solid #b1b9c7;
  width: 100%;
}

.wrapperDay {
  display: flex;
  flex-direction: column;
  border: 1px solid #b1b9c7;
  cursor: pointer;
  padding: 12px 0;
  gap: 2px;
  align-items: center;
  justify-content: center;
  color: #adadad;
  position: relative;
}

.wrapperDay.active {
  background: var(--primary-light) !important;
  color: var(--primary) !important;
}

.wrapperDay:hover {
  color: var(--primary);
}

.wrapperDay p {
  margin: 0;
}

.timeDay {
  font-weight: 600;
  font-size: 14px;
}

.labelDay {
  font-weight: 400;
  font-size: 12px;
}

.positionSpinner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(243, 240, 249, 0.3);
}
